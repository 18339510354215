import BrandLogo from "./Components/BrandLogo/BrandLogo";
import ContactForm from "./Components/ContactForm/ContactForm";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import TopBanner from "./Components/TopBanner/TopBanner"

function App() {
  return (
    <div className="App">
      <Navbar />
      <TopBanner />
      <BrandLogo />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;

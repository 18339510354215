import React, { useState } from "react";
import './Navbar.css';
import { FaBars, FaTimes } from "react-icons/fa"; // Import React Icons
import logo from '../../images/logo-destink.png'; // Replace with the path to your logo image
import phoneIcon from '../../images/phone-icon.png'; // Replace with the path to your phone icon image

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <nav className="navbar-section" id="navbar">
                <div className="navbar-container">
                    {/* Logo Section */}
                    <div className="navbar-logo">
                        <img src={logo} alt="Destink Logo" />
                    </div>
                    
                    {/* Hamburger Icon */}
                    <div 
                        className="hamburger-icon" 
                        onClick={toggleMenu}
                    >
                        {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                    </div>
                    
                    {/* Navigation Menu */}
                    <ul className={`navbar-menu ${isMenuOpen ? 'active' : ''}`}>
                        <li><a href="#home">Home</a></li>
                        <li><a href="#about">About</a></li>
                        <li className="dropdown">
                            <a href="#destinations">Destinations</a>
                        </li>
                        <li><a href="#tours">All Tours</a></li>
                        <li><a href="#faqs">FAQs</a></li>
                        <li><a href="#contact">Contact Us</a></li>
                        {/* Contact Section - Add to Hamburger Menu */}
                        <li className="navbar-contact-mobile">
                            <img src={phoneIcon} alt="Phone Icon" className="phone-icon" />
                            <span>1 (800) 598-5672</span>
                        </li>
                    </ul>
                    
                    {/* Contact Section for Desktop */}
                    <div className="navbar-contact">
                        <img src={phoneIcon} alt="Phone Icon" className="phone-icon" />
                        <span>1 (800) 598-5672</span>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbar;

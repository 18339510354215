import React from "react";
import './Footer.css';

function Footer() {

    const currentYear = new Date().getFullYear();
    return (
        <>
            <section className="footer-section">
                <div className="footer-container">
                    
                  <p>© Copyright {currentYear} Destink. All Rights Reserved</p>
                    
                </div>
            </section>
        </>
    );
}

export default Footer;

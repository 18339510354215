import React from "react";
import './TopBanner.css'

import bannerImage from "../../images/destink-banner-final.png"

function TopBanner() {
    return (
        <>
            {/* Banner Section */}
            <div className="top-banner">
                <div className="top-banner-image">
                    <img src={bannerImage} alt="Contact Us Banner" /> 
                </div>
            </div>
        </>
    )
}

export default TopBanner;

import React from "react";
import "./BrandLogo.css";

// Importing logo images
import alaskaAir from '../../images/logo/alaskaair-logo.svg';
import americanAirlines from '../../images/logo/americanairlines-logo.png';
import deltaAirlines from '../../images/logo/delta-png.png';
import envoy from '../../images/logo/envoy-png.png';
import hawai from '../../images/logo/hawaiianairlines-Logo.png';
import republic from '../../images/logo/republic-airways-png.png';
import skywest from '../../images/logo/SkyWest-logo.svg';
import southwest from '../../images/logo/southwest-logo.svg';
import spirit from '../../images/logo/spirit-Logo.svg';
import suncountry from '../../images/logo/suncountry-airlines-logo.svg';
import allegiant from '../../images/logo/alligiant-png.png';
import jetBlue from '../../images/logo/jetBlue-png.png';

// Array of logo images
const logos = [
    { src: alaskaAir, alt: "Brand Logo 1" },
    { src: americanAirlines, alt: "Brand Logo 2" },
    { src: deltaAirlines, alt: "Brand Logo 3" },
    { src: envoy, alt: "Brand Logo 4" },
    { src: hawai, alt: "Brand Logo 5" },
    { src: republic, alt: "Brand Logo 6" },
    { src: skywest, alt: "Brand Logo 7" },
    { src: southwest, alt: "Brand Logo 8" },
    { src: spirit, alt: "Brand Logo 9" },
    { src: suncountry, alt: "Brand Logo 10" },
    { src: allegiant, alt: "Brand Logo 11" },
    { src: jetBlue, alt: "Brand Logo 12" },
];

function BrandLogo() {
    return (
        <>
            <section className="brand-logo-section" id="brand-logo">
                <div className="brand-logo-container">
                    <h2 className="brand-logo-heading">Trusted Alliances for Seamless Travel</h2>
                    <p className="brand-logo-description">
                    We are proud to collaborate with leading airlines, offering an extensive network of daily flights to numerous destinations across the U.S. and Canada. Through these partnerships, we ensure reliable and seamless travel experiences for our customers, prioritizing convenience and exceptional service every step of the way.
                    </p>
                    <div className="brand-logo-grid">
                        {/* Render logo images dynamically */}
                        {logos.map((logo, index) => (
                            <div className="brand-logo-item" key={index}>
                                <img src={logo.src} alt={logo.alt} />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default BrandLogo;

import React, { useState } from "react";
import './ContactForm.css';

function ContactForm() {
    const [wasValidated, setWasValidated] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        cardName: '',
        cardNumber: '',
        expirationDate: '',
        cvcCode: '',
        amount: '',
        streetAddress: '',
        city: '',
        state: '',
        zip: '',
        repId: '', // Added Rep ID
        description: '' // Added Description
    });
    const [message, setMessage] = useState({ text: '', type: '' }); // Stores success or error message

    const resetValidationState = () => {
        const inputs = document.querySelectorAll('input.was-validated');
        inputs.forEach(input => input.classList.remove('was-validated'));
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent form submission

        const form = event.target;
        if (form.checkValidity()) {
            try {
                const response = await fetch('https://checkout.destink.com/send-email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                const result = await response.json();
                if (response.ok) {
                    setMessage({ text: 'Form submitted successfully!', type: 'success' });
                    setFormData({
                        name: '',
                        cardName: '',
                        cardNumber: '',
                        expirationDate: '',
                        cvcCode: '',
                        amount: '',
                        streetAddress: '',
                        city: '',
                        state: '',
                        zip: '',
                        repId: '', // Reset Rep ID
                        description: '' // Reset Description
                    });
                    resetValidationState(); // Reset validation state on successful submission
                    setWasValidated(false); // Reset validation flag

                    // Clear success message after 5 seconds
                    setTimeout(() => {
                        setMessage({ text: '', type: '' });
                    }, 5000);
                } else {
                    setMessage({ text: 'Error submitting form: ' + result.message, type: 'error' });
                }
            } catch (error) {
                setMessage({ text: 'Error submitting form: ' + error.message, type: 'error' });
            }
        } else {
            setWasValidated(true); // Trigger validation when fields are not valid
        }
    };

    const handleBlur = (event) => {
        event.target.classList.add("was-validated");
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <section className="contact-form-section" id="contact-form">
            <div className="contact-form-container">
                <h2 className="form-heading">Payment Information</h2>
                <form
                    className={`form ${wasValidated ? "was-validated" : ""}`}
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <div className="form-group">
                        <label htmlFor="name">Name of the Primary Passenger</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            placeholder="Enter passenger name"
                            required
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={wasValidated && !formData.name ? 'is-invalid' : ''}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="cardName">Name on the Card</label>
                        <input
                            type="text"
                            id="cardName"
                            name="cardName"
                            value={formData.cardName}
                            placeholder="Enter name on card"
                            required
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={wasValidated && !formData.cardName ? 'is-invalid' : ''}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="cardNumber">Credit Card Number</label>
                        <input
                            type="text"
                            id="cardNumber"
                            name="cardNumber"
                            value={formData.cardNumber}
                            placeholder="Enter credit card number"
                            required
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={wasValidated && !formData.cardNumber ? 'is-invalid' : ''}
                        />
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="expirationDate">Expiration Date</label>
                            <input
                                type="text"
                                id="expirationDate"
                                name="expirationDate"
                                value={formData.expirationDate}
                                placeholder="MM/YY"
                                required
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className={wasValidated && !formData.expirationDate ? 'is-invalid' : ''}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="cvcCode">CVC Code</label>
                            <input
                                type="text"
                                id="cvcCode"
                                name="cvcCode"
                                value={formData.cvcCode}
                                placeholder="Enter CVC"
                                required
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className={wasValidated && !formData.cvcCode ? 'is-invalid' : ''}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="amount">Amount</label>
                        <input
                            type="text"
                            id="amount"
                            name="amount"
                            value={formData.amount}
                            placeholder="Enter amount"
                            required
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={wasValidated && !formData.amount ? 'is-invalid' : ''}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="streetAddress">Street Address</label>
                        <input
                            type="text"
                            id="streetAddress"
                            name="streetAddress"
                            value={formData.streetAddress}
                            placeholder="Enter street address"
                            required
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={wasValidated && !formData.streetAddress ? 'is-invalid' : ''}
                        />
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="city">City</label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                value={formData.city}
                                placeholder="Enter city"
                                required
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className={wasValidated && !formData.city ? 'is-invalid' : ''}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="state">State</label>
                            <input
                                type="text"
                                id="state"
                                name="state"
                                value={formData.state}
                                placeholder="Enter state"
                                required
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className={wasValidated && !formData.state ? 'is-invalid' : ''}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="zip">Zip</label>
                            <input
                                type="text"
                                id="zip"
                                name="zip"
                                value={formData.zip}
                                placeholder="Enter zip code"
                                required
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className={wasValidated && !formData.zip ? 'is-invalid' : ''}
                            />
                        </div>
                    </div>
                    {/* New fields */}
                    <div className="form-group">
                        <label htmlFor="repId">Rep ID</label>
                        <input
                            type="text"
                            id="repId"
                            name="repId"
                            value={formData.repId}
                            placeholder="Enter Rep ID"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <textarea
                            id="description"
                            name="description"
                            value={formData.description}
                            placeholder="Enter Description"
                            rows="4"
                            onChange={handleChange}
                        ></textarea>
                    </div>
                    <button type="submit" className="submit-btn">Submit</button>
                    {message.text && (
                        <p className={`message ${message.type === 'success' ? 'success' : 'error'}`}>
                            {message.text}
                        </p>
                    )}
                </form>
            </div>
        </section>
    );
}

export default ContactForm;
